export const comments = {
  "ja": {
    "score": {
      "up": "生活習慣スコアは7月より%d点上がりました。変化が見られたのはどの項目ですか？<br>\n8月末から9月にかけ、ようやく暑さが鎮まるといわれる暦の上での「<b>処暑</b>」という季節を迎えますが、まだまだ暑い日々が続きます。9月の特集では、そんな暑い日々でもお仕事の効率をUPさせるコツについてご紹介いたします。ぜひご活用ください。\n\n",
      "down": "生活習慣スコアは7月より%d点下がりました。変化が見られたのはどの項目ですか？<br>\n8月末から9月にかけ、ようやく暑さが鎮まるといわれる暦の上での「<b>処暑</b>」という季節を迎えますが、まだまだ暑い日々が続きます。9月の特集では、そんな暑い日々でもお仕事の効率をUPさせるコツについてご紹介いたします。ぜひご活用ください。\n\n",
      "equal100": "生活習慣スコアは7月に引き続き%満点%です。素晴らしい！<br>\n8月末から9月にかけ、ようやく暑さが鎮まるといわれる暦の上での「<b>処暑</b>」という季節を迎えますが、まだまだ暑い日々が続きます。9月の特集では、そんな暑い日々でもお仕事の効率をUPさせるコツについてご紹介いたします。ぜひご活用ください。\n\n",
      "equal": "生活習慣スコアは7月と同じ点数です。<br>\n8月末から9月にかけ、ようやく暑さが鎮まるといわれる暦の上での「<b>処暑</b>」という季節を迎えますが、まだまだ暑い日々が続きます。9月の特集では、そんな暑い日々でもお仕事の効率をUPさせるコツについてご紹介いたします。ぜひご活用ください。\n\n"
    },
    "steps": {
      "up": "歩数スコアは7月より%d点上がりました。この調子で頑張りましょう！<br>\n9/27より<b>Walk 個人チャレンジ 2024 2nd Round</b>のエントリーが開始いたします。良い記録を目指して、<a href=\"https://go.andwell.jp/library/tag/9bd90e22-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>から歩く時のポイントを学んでおきましょう。\n\n",
      "down": "歩数スコアは7月より%d点下がりました。切り替えて頑張りましょう！<br>\n9/27より<b>Walk 個人チャレンジ 2024 2nd Round</b>のエントリーが開始いたします。良い記録を目指して、<a href=\"https://go.andwell.jp/library/tag/9bd90e22-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>から歩く時のポイントを学んでおきましょう。\n\n",
      "equal": "歩数スコアは7月と同じ点数です。<br>\n9/27より<b>Walk 個人チャレンジ 2024 2nd Round</b>のエントリーが開始いたします。良い記録を目指して、<a href=\"https://go.andwell.jp/library/tag/9bd90e22-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>から歩く時のポイントを学んでおきましょう。\n\n"
    },
    "exercise": {
      "up": "運動のスコアは7月より%d点上がりました。素晴らしい！<br>\n日常生活の動きも立派な運動です！歩く時に普段より5cm歩幅を広げるだけでも、運動効果が高まるといわれます。\n\n",
      "down": "運動のスコアは7月より%d点下がりました。先月の自分を振り返ってみましょう。<br>\n日常生活の動きも立派な運動です！歩く時に普段より5cm歩幅を広げるだけでも、運動効果が高まるといわれます。\n\n",
      "equal": "運動のスコアは7月と同じ点数です。<br>\n日常生活の動きも立派な運動です！歩く時に普段より5cm歩幅を広げるだけでも、運動効果が高まるといわれます。\n\n"
    },
    "meal": {
      "up": "食事のスコアは7月より%d点上がりました。<br>\nまだまだ暑い日が続きます。水分補給は忘れずにこまめに行いましょう。1回あたりコップ1杯(200ml)程度が目安量です。\n\n",
      "down": "食事のスコアは7月より%d点下がりました。<br>\nまだまだ暑い日が続きます。水分補給は忘れずにこまめに行いましょう。1回あたりコップ1杯(200ml)程度が目安量です。\n\n",
      "equal": "食事のスコアは7月と同じ点数です。<br>\nまだまだ暑い日が続きます。水分補給は忘れずにこまめに行いましょう。1回あたりコップ1杯(200ml)程度が目安量です。\n\n"
    },
    "drinking": {
      "up": "飲酒のスコアは7月より%d点上がりました。<br>\nお酒を飲む時には一緒に食べるおつまみも重要です。野菜サラダや枝豆、冷奴、海藻類などがおすすめ。詳しくは<a href=\"https://go.andwell.jp/library/tag/9e3d63fe-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>から。\n\n",
      "down": "飲酒のスコアは7月より%d点下がりました。飲み過ぎには気をつけましょう。<br>\nお酒を飲む時には一緒に食べるおつまみも重要です。野菜サラダや枝豆、冷奴、海藻類などがおすすめ。詳しくは<a href=\"https://go.andwell.jp/library/tag/9e3d63fe-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>から。\n\n",
      "equal": "飲酒のスコアは7月と同じ点数です。<br>\nお酒を飲む時には一緒に食べるおつまみも重要です。野菜サラダや枝豆、冷奴、海藻類などがおすすめ。詳しくは<a href=\"https://go.andwell.jp/library/tag/9e3d63fe-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>から。\n\n"
    },
    "sleep": {
      "up": "睡眠のスコアは7月より%d点上がりました。<br>\n太陽光を浴びることは体内時計のリズムを整えるのに必要不可欠！朝起きてすぐに、朝日を浴びることが質の良い睡眠につながります。\n\n",
      "down": "睡眠のスコアは7月より%d点下がりました。<br>\n太陽光を浴びることは体内時計のリズムを整えるのに必要不可欠！朝起きてすぐに、朝日を浴びることが質の良い睡眠につながります。\n\n",
      "equal": "睡眠のスコアは7月と同じ点数です。<br>\n太陽光を浴びることは体内時計のリズムを整えるのに必要不可欠！朝起きてすぐに、朝日を浴びることが質の良い睡眠につながります。\n\n"
    },
    "stress": {
      "up": "ストレスのスコアは7月より%d点上がりました。<br>\n蓄積した夏の暑さによる疲れに、気候の変化が加わって体調不良を起こす「<b>秋バテ</b>」にご注意。体を冷やさないよう、冷たい食べ物や飲み物の摂り過ぎには気をつけましょう。\n\n",
      "down": "ストレスのスコアは7月より%d点下がりました。<br>\n蓄積した夏の暑さによる疲れに、気候の変化が加わって体調不良を起こす「<b>秋バテ</b>」にご注意。体を冷やさないよう、冷たい食べ物や飲み物の摂り過ぎには気をつけましょう。\n\n",
      "equal": "ストレスのスコアは7月と同じ点数です。<br>\n蓄積した夏の暑さによる疲れに、気候の変化が加わって体調不良を起こす「<b>秋バテ</b>」にご注意。体を冷やさないよう、冷たい食べ物や飲み物の摂り過ぎには気をつけましょう。\n\n"
    }
  },
  "en": {
    "score": {
      "up": "Your lifestyle score has gone up by %d points since July. What areas have you seen changes in?<br>\nFrom the end of August to September, we enter the \"<b>End of heat</b>\" season on the calendar, when the heat is said to finally subside, but the days are still hot. In our September special issue, we will introduce tips to improve work efficiency even in such hot weather. We hope you will find them useful.\n\n",
      "down": "Your lifestyle score has gone down by %d points since July. What areas have you seen changes in?<br>\nFrom the end of August to September, we enter the \"<b>End of heat</b>\" season on the calendar, when the heat is said to finally subside, but the days are still hot. In our September special issue, we will introduce tips to improve work efficiency even in such hot weather. We hope you will find them useful.\n\n",
      "equal100": "Your lifestyle score is perfect, like it was in July. Wonderful!<br>\nFrom the end of August to September, we enter the \"<b>End of heat</b>\" season on the calendar, when the heat is said to finally subside, but the days are still hot. In our September special issue, we will introduce tips to improve work efficiency even in such hot weather. We hope you will find them useful.\n\n",
      "equal": "Your lifestyle score is the same as it was in July. <br>\nFrom the end of August to September, we enter the \"<b>End of heat</b>\" season on the calendar, when the heat is said to finally subside, but the days are still hot. In our September special issue, we will introduce tips to improve work efficiency even in such hot weather. We hope you will find them useful.\n\n"
    },
    "steps": {
      "up": "Your steps score has gone up %d points since July. Keep up the good work!<br>\nEntries for the <b>Individual Walk Challenge 2024 2nd Round</b> will open on September 27. Learn about the key points of the walk <a href=\"https://go.andwell.jp/library/tag/9bd90e22-5e2f-11ec-9ce8-0af363eb5062\">here</a> in order to aim for a good record.\n\n",
      "down": "Your steps score has gone down %d points since July. Try making some changes and do your best!<br>\nEntries for the <b>Individual Walk Challenge 2024 2nd Round</b> will open on September 27. Learn about the key points of the walk <a href=\"https://go.andwell.jp/library/tag/9bd90e22-5e2f-11ec-9ce8-0af363eb5062\">here</a> in order to aim for a good record.\n\n",
      "equal": "Your steps score is the same as it was in July. <br>\nEntries for the <b>Individual Walk Challenge 2024 2nd Round</b> will open on September 27. Learn about the key points of the walk <a href=\"https://go.andwell.jp/library/tag/9bd90e22-5e2f-11ec-9ce8-0af363eb5062\">here</a> in order to aim for a good record.\n\n"
    },
    "exercise": {
      "up": "Your exercise score has gone up %d points since July. Wonderful!<br>\nDaily movement is also a great form of exercise! It is said that even a 5 cm wider stride than usual when walking can increase the effect of exercise.\n\n",
      "down": "Your exercise score has gone down %d points since July. Try looking back at yourself last month.<br>\nDaily movement is also a great form of exercise! It is said that even a 5 cm wider stride than usual when walking can increase the effect of exercise.\n\n",
      "equal": "Your exercise score is the same as it was in July. <br>\nDaily movement is also a great form of exercise! It is said that even a 5 cm wider stride than usual when walking can increase the effect of exercise.\n\n"
    },
    "meal": {
      "up": "Your food score has gone up %d points since July.<br>\nHot weather is still continuing. Remember to drink plenty of water, about 1 glass (200 ml) at a time.\n\n",
      "down": "Your food score has gone down %d points since July.<br>\nHot weather is still continuing. Remember to drink plenty of water, about 1 glass (200 ml) at a time.\n\n",
      "equal": "Your food score is the same as it was in July. <br>\nHot weather is still continuing. Remember to drink plenty of water, about 1 glass (200 ml) at a time.\n\n"
    },
    "drinking": {
      "up": "Your alcohol score has gone up %d points since July.<br>\nWhen drinking alcohol, the snacks you eat with it are also important. Vegetable salad, edamame (green soybeans), chilled tofu, and seaweed are some recommendations. For more information, click <a href=\"https://go.andwell.jp/library/tag/9e3d63fe-5e2f-11ec-9ce8-0af363eb5062\">here</a>.\n\n",
      "down": "Your alcohol score has gone down %d points since July. Be careful not to drink too much.<br>\nWhen drinking alcohol, the snacks you eat with it are also important. Vegetable salad, edamame (green soybeans), chilled tofu, and seaweed are some recommendations. For more information, click <a href=\"https://go.andwell.jp/library/tag/9e3d63fe-5e2f-11ec-9ce8-0af363eb5062\">here</a>.\n\n",
      "equal": "Your alcohol score is the same as it was in July. <br>\nWhen drinking alcohol, the snacks you eat with it are also important. Vegetable salad, edamame (green soybeans), chilled tofu, and seaweed are some recommendations. For more information, click <a href=\"https://go.andwell.jp/library/tag/9e3d63fe-5e2f-11ec-9ce8-0af363eb5062\">here</a>.\n\n"
    },
    "sleep": {
      "up": "Your sleep score has gone up %d points since July.<br>\nExposure to sunlight is essential to regulate the rhythm of the body's internal clock! Exposure to the morning sun as soon as you wake up will help you get a good night's sleep.\n\n",
      "down": "Your sleep score has gone down %d points since July.<br>\nExposure to sunlight is essential to regulate the rhythm of the body's internal clock! Exposure to the morning sun as soon as you wake up will help you get a good night's sleep.\n\n",
      "equal": "Your sleep score is the same as it was in July. <br>\nExposure to sunlight is essential to regulate the rhythm of the body's internal clock! Exposure to the morning sun as soon as you wake up will help you get a good night's sleep.\n\n"
    },
    "stress": {
      "up": "Your stress score has gone up %d points since July.<br>\nBeware of <b>\"autumn fatigue,\"</b> a condition in which accumulated summer heat exhaustion is combined with changes in weather conditions to cause poor physical condition. Be careful not to consume too many cold foods and drinks to keep your body cool.\n\n",
      "down": "Your stress score has gone down %d points since July.<br>\nBeware of <b>\"autumn fatigue,\"</b> a condition in which accumulated summer heat exhaustion is combined with changes in weather conditions to cause poor physical condition. Be careful not to consume too many cold foods and drinks to keep your body cool.\n\n",
      "equal": "Your stress score is the same as it was in July. <br>\nBeware of <b>\"autumn fatigue,\"</b> a condition in which accumulated summer heat exhaustion is combined with changes in weather conditions to cause poor physical condition. Be careful not to consume too many cold foods and drinks to keep your body cool.\n\n"
    }
  }
}